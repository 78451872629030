<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{operationTitle}}
            <p><small>Manage Source of Leads</small></p>
          </template>
          <template v-slot:toolbar>
            <b-input-group-append>
              <router-link to="/assets/create"
                           v-if="globalPropertyId > 0 && $global.hasPermission('assetsstore')"
              >
                <b-button class="btn btn-primary font-weight-bolder"
                          v-b-tooltip.hover :title="`Add New`"
                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                </b-button>
              </router-link>
            </b-input-group-append>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="assets-table"
                   v-if="$global.hasPermission('assetsview')">

                <div class="w-100 mb-6">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length"><label>Show
                        <b-form-select v-model="pagination.totalPerPage"
                                       class="datatable-select"
                                       :options="[10, 50, 100]"
                                       size="sm"></b-form-select>
                        entries</label></div>

                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="kt_datatable_filter" class="dataTables_filter"><label>Search:
                        <b-form-input type="search"
                                      class="form-control form-control-sm ml-2 w-25"
                                      label="Search"
                                      v-on:keyup.enter="handleSearch"
                                      v-model="search"></b-form-input>
                      </label>
                      </div>
                    </div>
                  </div>
                </div>

                <v-data-table
                    responsive
                    ref="table"
                    :headers="columns"
                    :items="dataSource"
                    :loading="loading"
                    :items-per-page="parseInt(pagination.totalPerPage)"
                    hide-default-footer
                    class="table table-head-custom table-vertical-center table-responsive"

                >
                  <template v-slot:item.id="record">
                    {{ dataSource.length - record.index }}
                  </template>
                  <template v-slot:item.type="{item}">
                    {{
                    item.type ? "Custom" : "Default" }}
                  </template>
                  <template v-slot:item.action="{item}" class="action-column">
                    <router-link :to="`assets/edit/${item.id}`">
                      <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"
                         v-show="$global.hasPermission('assetsupdate')" href="javascript:;">
                            <span
                                class="svg-icon svg-icon-md svg-icon-primary">
                              <!--beggin::Svg Icon-->
                              <inline-svg
                                  src="/media/svg/icons/Communication/Write.svg"
                                  class="action-edit-button"
                              ></inline-svg>
                              <!--end::Svg Icon-->
                            </span>
                      </a>
                    </router-link>
                    <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                       v-show="$global.hasPermission('assetsdestroy')"
                       @click="handleDeleteOperation(item.id)">
                        <span
                            class="svg-icon svg-icon-md svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <inline-svg
                              src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                          ></inline-svg>
                          <!--end::Svg Icon-->
                        </span>
                    </a>
                  </template>
                </v-data-table>
                <div class="row">
                  <div class="pagination-box">
                    <b-pagination
                        v-model="pagination.current"
                        :total-rows="pagination.total"
                        :per-page="pagination.totalPerPage"
                        size="lg"
                    ></b-pagination>

                  </div>
                </div><!-- /.pull-right -->

              </div><!-- /.pull-right -->
            </v-card>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import KTCard from "@/view/content/Card.vue";
  import ListingMixin from "@/assets/js/components/ListingMixin";
  import ModuleMixing from "./module.mixin";


  export default {
    mixins: [ListingMixin, ModuleMixing],
    data() {
      return {};
    },
    components: {
      KTCard,
    },
    computed: {
      ...mapState([
        'global',
      ]),
    },
  };
</script>
